.select-schools div[class*="-control"] {
  min-height: initial;
  padding: 1px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-schools div[class*="-ValueContainer"] {
  padding: 0px 16px;
}

.select-schools div[class*="-indicatorContainer"] {
  padding: 0;
}