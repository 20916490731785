h1 {
  font-weight: 400;
  font-size: 1.714rem;
  margin-bottom: 0;
}

h2 {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0;
}

h3 {
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 0;
}

strong {
  font-weight: 500;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
