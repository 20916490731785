.legend {
  display: flex;
  align-items: center;
  line-height: 1rem;

  &::before {
    width: 9px;
    height: 9px;
    content: '';
    display: block;
    background-color: #eee;
    border-radius: 100%;
    margin-right: 6px;
  }

  &.legend-correct::before {
    background-color: var(--success-color);
  }

  &.legend-wrong::before {
    background-color: var(--danger-color);
  }

  &.legend-nullified::before {
    background-color: var(--warning-color);
  }
}
