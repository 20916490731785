.sort-icon {
  fill: var(--gray-color);

  &.sorted-up .sort-up {
    fill: var(--primary-color);
  }

  &.sorted-down .sort-down {
    fill: var(--primary-color);
  }
}
