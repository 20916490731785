.steps {
  position: relative;

  > button {
    opacity: 1;
    color: var(--primary-color);
    border-color: var(--primary-color);
    background-color: #fff;

    &.active {
      background-color: var(--primary-color);
      color: #fff;
    }
  }

  &::after {
    width: calc(100% - 10px);
    height: 2px;
    content: '';
    display: block;
    background-color: var(--primary-color);
    position: absolute;
    margin-top: -1px;
    left: 5px;
    top: 50%;
  }
}
