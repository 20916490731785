@media (max-width: 575px) {
  :root {
    --spacing-margin: 16px;
    --spacing-padding: 16px;
  }
}

@media (max-width: 991px) {
  body {
    font-size: 0.875rem;
  }
}
